import { LinearProgress, Stack, styled, Typography } from '@mui/material'
import {
	DataGrid,
	GridColDef,
	GridFilterPanel,
	GridPaginationModel,
	GridRowModel,
	GridSortModel,
	useGridApiContext,
	useGridApiRef,
} from '@mui/x-data-grid'
import { Funnel, SortDescending } from '@phosphor-icons/react'
import { createSelector } from '@reduxjs/toolkit'
import { QueryDefinition } from '@reduxjs/toolkit/dist/query'
import { UseMutation, UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import _ from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { JsonParam, NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'

import { IconButton } from '@/components'
import { ContentPreloader } from '@/components/ContentPreloader'
import settings from '@/constants/http'
import { ICON_SIZES } from '@/constants/iconSizes'
import EntityScope from '@/features/onboarding/types/EntityScope'
import EntityTypes from '@/features/shareEntity/types/EntityTypes'
import { formatDateNew } from '@/utils/dateTime2'
import filterUndefinedFields from '../../../../utils/filterUndefinedFields'
import { ActionButtonProps } from '../../types/dashboardTypes'
import { GridConfigDto } from '../../types/dto/Grid'
import { CommonCard } from '../CommonCard'
import CustomActionsContainer from '../CustomActionsContainer'
import withRoleBasedErrors from '../withRoleBasedErrors'
import { CELL_TEMPLATE_DEFAULTS, RENDER_EDIT_CELL_TEMPLATE_DEFAULTS } from './templates/mapper'
import { Toolbar, ToolbarProps } from './Toolbar'
import VALUE_GETTERS_MAP from './valueGettersMap'

type ColumnConfig = {
	field: string
	template: any
	valueGetterTemplate: string
	headerName: string
	description: string
	filterable: boolean
	sortable: boolean
	editable: boolean
	pinnable: boolean
	groupable: boolean
	width: number
}

type MutationResolutionState = {
	resolve: (value: GridRowModel) => void
	reject: (reason?: any) => void
	newRow: GridRowModel
	oldRow: GridRowModel
}

export type metaQueryResponse<T extends string[] = string[]> = {
	fields: T
	dictionary: { [key in T[number]]: ColumnConfig }
	config: {
		pageSize: number
	}
}

export type TDataTableProps = {
	metaQuery: UseQuery<QueryDefinition<any, any, any, GridConfigDto>>
	listQuery: UseQuery<QueryDefinition<any, any, any, any>>
	mutationDefinition?: UseMutation<any>
	queryParams?: Record<string, any>
	metaQueryParams?: any
	isFilterEnabled?: boolean
	entityScope?: EntityScope
	entityType?: EntityTypes
	selectedEntities?: string[]
	setSelectedEntities?: React.Dispatch<React.SetStateAction<string[]>>
	description?: string
	tableActions?: ActionButtonProps[]
}

const StyledDataGrid = styled(DataGrid)<{}>(({ theme }) => ({
	maxHeight: '80%',
	'& .MuiDataGrid-columnHeaderTitle': {
		fontWeight: 700,
	},
	'&.MuiDataGrid-root': {
		border: 'none',
	},
	'& .MuiDataGrid-cell a': {
		color: theme.palette.primary.main,
		textDecoration: 'none',
		fontWeight: theme.typography.fontWeightMedium,
	},
}))

function CustomFilterPanel() {
	const [, setSearchQuery] = useQueryParams({
		filters: withDefault(JsonParam, undefined),
	})
	const apiRef = useGridApiContext()

	const applyFilters = () => {
		setSearchQuery({ filters: apiRef.current?.state?.filter.filterModel }, 'pushIn')
		apiRef.current.hideFilterPanel()
	}

	return (
		<div>
			<GridFilterPanel />
			<CustomActionsContainer>
				<IconButton variant="primary" size="sm" onClick={applyFilters}>
					Apply
				</IconButton>
			</CustomActionsContainer>
		</div>
	)
}

// @TODO: Disable edits on entities with 'posted' status https://mui.com/x/react-data-grid/editing/#server-side-persistence
// @TODO: Add validation to column limit
// @TODO: Move the ContentModeration Page into Feeds Main Page (discuss that this belongs in messages, other page; comments),
// @TODO: Confirmation toast on deletion: https://mui.com/x/react-data-grid/editing/#confirm-before-saving
// @TODO: (performance) Debounce in all editable fields: https://mui.com/x/react-data-grid/editing/#with-debounce
// @TODO: Disable select outside of checkbox for each editable
const DataTable = ({
	metaQuery,
	listQuery,
	mutationDefinition,
	queryParams,
	metaQueryParams,
	entityScope,
	entityType,
	description,
	selectedEntities,
	setSelectedEntities,
	isFilterEnabled = true,
	tableActions = [],
}: TDataTableProps) => {
	const apiRef = useGridApiRef()
	const { data: meta, isLoading: isConfigLoading } = metaQuery(filterUndefinedFields({ entityType, entityScope, ...metaQueryParams }))
	const [searchQuery, setSearchQuery] = useQueryParams({
		filters: withDefault(JsonParam, undefined),
		sortBy: withDefault(JsonParam, undefined),
		skip: withDefault(NumberParam, 0),
		take: withDefault(NumberParam, settings.DATA_GRID_PAGE_SIZE),
		fields: withDefault(JsonParam, {}),
		density: withDefault(StringParam, 'comfortable'),
		q: withDefault(StringParam, ''),
	})
	const [mutation] = mutationDefinition?.() ?? [undefined]

	const selectData = useMemo(() => {
		const emptyData = { totalItems: 0, items: [] }
		return createSelector(
			(data) => data,
			(data: { totalItems: number; items: any[] } | undefined): { totalItems: number; items: any[] } => {
				return data ? data : emptyData
			},
		)
	}, [])

	const handleSortModelChanged = useCallback(
		(model: GridSortModel) => {
			setSearchQuery({ sortBy: model.length > 0 ? model : undefined }, 'pushIn')
		},
		[setSearchQuery],
	)

	const handlePaginationModelChange = useCallback(
		(model: GridPaginationModel) => {
			setSearchQuery({ skip: model.page * model.pageSize, take: model.pageSize }, 'pushIn')
		},
		[setSearchQuery],
	)

	const handleRowUpdate = useCallback(
		async (newRow: MutationResolutionState['newRow'], oldRow: MutationResolutionState['oldRow']) =>
			new Promise<GridRowModel>(async (resolve, reject) => {
				const shouldUpdateRow = ({ oldRow, newRow }) => {
					let _new = structuredClone(newRow)
					let _old = structuredClone(oldRow)

					Object.entries(meta.dictionary).forEach(([key, { template }]) => {
						if (['date', 'dateTime'].includes(template)) {
							_old[key] = formatDateNew(oldRow[key], 'dateTimeShort')
							_new[key] = formatDateNew(newRow[key], 'dateTimeShort')
						}
					})

					return !_.isEqual(_old, _new)
				}

				if (!!mutation && shouldUpdateRow({ oldRow, newRow })) {
					const { isEditable, ...rowModification } = newRow
					await mutation(rowModification)

					resolve(newRow)
				} else {
					resolve(oldRow)
				}
			}),
		[mutation, apiRef, meta],
	)

	const paginationModel: GridPaginationModel = useMemo(
		() => ({
			page: searchQuery.skip / searchQuery.take,
			pageSize: searchQuery.take,
		}),
		[searchQuery.skip, searchQuery.take],
	)

	const {
		data: rowData,
		isLoading,
		refetch,
	} = listQuery(
		{
			skip: searchQuery.skip,
			take: searchQuery.take,
			sortBy: searchQuery.sortBy,
			filters: searchQuery.filters,
			...filterUndefinedFields({ entityType, entityScope, ...queryParams }),
		},
		{
			skip: isConfigLoading,
			selectFromResult: (result) => ({
				...result,
				data: selectData(result.data),
			}),
		},
	)

	const columns: GridColDef[] = useMemo(() => {
		return meta?.fields.map((field) => {
			const { template, valueGetterTemplate, ...column } = meta.dictionary[field]
			column['field'] = field
			if (!column['width']) {
				column['flex'] = 1
			}

			if (CELL_TEMPLATE_DEFAULTS[template]) {
				column['renderCell'] = CELL_TEMPLATE_DEFAULTS[template]
			}

			if (column.editable && RENDER_EDIT_CELL_TEMPLATE_DEFAULTS[template]) {
				// column['renderEditCell'] = RENDER_EDIT_CELL_TEMPLATE_DEFAULTS[template]
				// column['renderEditCellProps'] = { handleChange: updateRow }
			}

			if (VALUE_GETTERS_MAP[valueGetterTemplate]) {
				column['valueGetter'] = VALUE_GETTERS_MAP[valueGetterTemplate]
			}
			return column
		})
	}, [meta])

	const slots = useMemo(
		() => ({
			loadingOverlay: LinearProgress as any,
			noResultsOverlay: () => (
				<Stack height="100%" alignItems="center" justifyContent="center">
					No results with specified filters, try expanding your search.
				</Stack>
			),
			noRowsOverlay: () => (
				<Stack height="100%" alignItems="center" justifyContent="center">
					No results found.
				</Stack>
			),
			filterPanel: CustomFilterPanel,
			toolbar: Toolbar,
		}),
		[],
	)

	const clearFilters = useCallback(() => {
		apiRef.current.setFilterModel({ items: [] })
		setSearchQuery({ filters: undefined }, 'replaceIn')
	}, [apiRef, setSearchQuery])

	const clearSort = useCallback(() => setSearchQuery({ sortBy: undefined }, 'replaceIn'), [setSearchQuery])

	const slotProps = useMemo(
		() => ({
			toolbar: {
				actions: [
					{
						label: 'Clear Filters',
						icon: <Funnel size={ICON_SIZES.sm} weight="bold" />,
						onClick: clearFilters,
						isVisible: isFilterEnabled && !!searchQuery.filters,
					},
					{
						label: 'Clear Sort',
						isVisible: searchQuery.sortBy,
						icon: <SortDescending size={ICON_SIZES.sm} weight="bold" />,
						onClick: clearSort,
					},
					...tableActions,
				],
				isFilterEnabled,
			} satisfies ToolbarProps,
		}),
		[isFilterEnabled, searchQuery.sortBy, searchQuery.filters, clearFilters, clearSort, tableActions],
	)

	const onRowSelectionModelChange = useCallback(
		(selected) => {
			setSelectedEntities(selected)
		},
		[setSelectedEntities],
	)

	return (
		<CommonCard style={{ paddingBottom: 'max(30px, 2%)', maxHeight: '92%', marginBottom: '4%' }}>
			<div style={{ height: 600, width: '100%', marginTop: '16px', position: 'relative' }}>
				{isConfigLoading ? (
					<ContentPreloader />
				) : (
					<>
						<Typography variant="body2" style={{ paddingRight: 16 }}>
							{description ??
								'Exporting to .csv will only export what is shown on this page (up to 100 rows). Please download multiple reports or contact Customer Support if you need a larger export.'}
						</Typography>
						<StyledDataGrid
							apiRef={apiRef}
							disableColumnFilter={!isFilterEnabled}
							sortModel={searchQuery.sortBy}
							loading={isLoading}
							columns={columns}
							rows={rowData.items}
							slots={slots}
							slotProps={slotProps}
							paginationModel={paginationModel}
							filterMode="server"
							sortingMode="server"
							editMode="row"
							isCellEditable={(params) => params.row.isEditable}
							processRowUpdate={handleRowUpdate}
							paginationMode="server"
							onSortModelChange={handleSortModelChanged}
							onPaginationModelChange={handlePaginationModelChange}
							pageSizeOptions={[10, settings.DATA_GRID_PAGE_SIZE, 100]}
							rowCount={rowData.totalItems || 0}
							{...(Array.isArray(selectedEntities)
								? { checkboxSelection: true, rowSelectionModel: selectedEntities, onRowSelectionModelChange }
								: {})}
						/>
					</>
				)}
			</div>
		</CommonCard>
	)
}

export const DataTableWithRoleCheck = withRoleBasedErrors(React.memo(DataTable))
export default React.memo(DataTable)
